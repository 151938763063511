.dashboard-top
{
    width: 100%;
    padding: 1em 1.5em;
    box-sizing: border-box;
    display: flex;
    gap: 0.5em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    align-items: center;
    background-color: white;
}
.dashboard-name > strong {
    font-weight: 900;
    letter-spacing: 0.1em;
}
.dashboard-name {
    font-family: 'Lexend Giga', sans-serif;
}
.dashboard-padding
{
    flex: 1;
    flex-shrink: 0;
}
.dashboard-edit
{
    display: flex;
    gap: 1em;
    align-items: center;
}

@media (max-width: 760px)
{
    .dashboard-name {
        display: none;
    }
    .dashboard-padding
    {
        display: none;
    }
    .bar-pages
    {
        width: 100%;
        font-size: 1rem;
        justify-content: unset;
        padding: 1em;
    }
    .user-block
    {
        display: none;
    }
}