.tour-li
{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
    box-sizing: border-box;
    border-radius: 1em;
    background-color: white;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.1s;
    height: fit-content;
}
.tour-li-info
{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.tour-li:hover
{
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    transform: translate(0.2em, -0.2em);
}
.tour-li-image
{
    min-width: none;
    min-height: none;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 0.5em;
    object-fit: cover;
}
.tour-li-title
{
    font-size: 1.5em;
    font-weight: bold;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
}
.tour-li-description
{
    font-weight: 300;
    opacity: 0.7;
    width: 100%;
}
.tour-li-price
{
    align-self: flex-end;
    font-size: 1.7em;
    font-weight: bold;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
}