.modal-overlay
{
    display: none;
}
.modal-overlay.enabled
{
    position: sticky;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    padding: 3em;
    box-sizing: border-box;
}
.modal-overlay-bg
{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    pointer-events:none;
}
.modal-container
{
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
    z-index: 30;
    position: relative;
    padding: 2em;
    box-sizing: border-box;
}
.modal-close
{
    width: 3em;
    height: 3em;
    border-radius: 3em;
}
.modal-overlay.enabled .modal-container
{
    pointer-events: visibleFill;
    background-color: white;
    padding: 5em 2em;
    padding-bottom: 2em;
    box-sizing: border-box;
    border-radius: 1em;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.1);
}
.modal-top
{
    position: absolute;
    width: calc(100% - 4em);
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 1em;
    left: 2em;
    max-height: 3em;
    overflow: hidden;
}
.modal-title
{
    font-family: 'Lexend Giga', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: -0.1em;
}
