.sidebar-button {
    cursor: pointer;
    padding: 0.5em;
    box-sizing: border-box;
    border-radius: 0.5em;
    transition: 0.1s ease-in-out;
}
.sidebar-button.enabled
{
    background-color: #f0f0f0;
    transform: scale(1.05);
}