.number-input
{
    display: flex;
    gap: 1.5rem;
    align-items: center;

    > .label
    {
        font-family: 'Lexend Giga', sans-serif;
        font-size: 1.5rem;
        letter-spacing: -0.1em;
        font-weight: bold; 
    }

    > .input
    {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        flex-grow: 1;
        font-family: 'Lexend Giga', sans-serif;
        font-size: 2.5rem;
        letter-spacing: -0.1em;
        font-weight: bolder;

        > input
        {
            padding: 0;
            font-weight: inherit;
            font-size: inherit;
            font-family: inherit;
            letter-spacing: inherit;
            border: none;
            width: var(--size);
            -moz-appearance: textfield;
            border-radius: 0;

            &::-webkit-inner-spin-button,&::-webkit-outer-spin-button
            {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus
            {
                outline: none;
            }
        }
    }
}