.loading-overlay
{
    width: 100vw;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}