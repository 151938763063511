.input-group
{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.horizontal
    {
        flex-direction: row;
    }

    > .title
    {
        font-family: 'Lexend Giga', sans-serif;
        font-size: 1.1rem;
        letter-spacing: -0.1em;
        font-weight: bold;
    }

    > .content
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}