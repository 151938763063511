.tour-images-panel
{
    padding: 1.5em;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(7em, 1fr));
    gap: 1em;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
}