.tour-include-icon-select-modal
{
    width: 60vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4em, 1fr));
    gap: 1em;
}
.tour-include-icon-select-modal .include-icon-select-btn
{
    aspect-ratio: 1;
}