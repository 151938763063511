.transfer-pricing-cars
{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    > .vehicles
    {
        
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}