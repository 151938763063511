.tour-includes-icon-select
{
    cursor: pointer;
    border-radius: 0.25em;
    border: 1px solid rgba(0,0,0,0.1);
    height: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 4px rgba(0,0,0,0);
    transition: 0.1s;
}
.tour-includes-icon-select:hover
{
    transform: translate(-2px, -2px);
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}