.image-preview-modal
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image-preview-modal img
{
    max-width: 100%;
    border-radius: 1em;
}