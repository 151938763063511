.tour-add-include-btn
{
    width: 100%;
}
.tour-includes-inner
{
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 0.5em;
    border: 1px solid rgba(0,0,0,0.1);
}
.tour-includes-panel
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
}