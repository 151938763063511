.panel-item:has(.tour-details-panel)
{
    height: 100%;
    max-height: 100%;
}
.tour-details-panel
{
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .toggle-btns
    {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &.visual textarea
    {
        display: none;
    }

    &.raw .ql-container, &.raw .ql-toolbar
    {
        display: none;
    }

    textarea
    {
        width: 100%;
        font-family: 'Courier New', Courier, monospace;
        font-weight: 500;
        flex: 1;
    }
}
.tour-details-panel .ql-container
{
    height: unset !important;
    flex: 1;
    overflow-y: auto;
    font-family: 'Lexend', sans-serif;
    font-size: 1.2em;
}