.tickets-outer-container
{
    width: 100%;
    flex: 1;
    display: flex;
    gap: 2em;
    align-items: start;
    max-height: 100%;
}
.tickets-inner-container
{
    align-self: stretch;
    flex: 1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
}

@media (max-width: 768px)
{
    .tickets-outer-container
    {
        flex-direction: column;
        gap: 1em;
    }
}