.edit-ticket-container
{
    width: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    gap: 2em;
}
.ticket-user-container
{
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
}