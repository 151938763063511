.tour-image-input
{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1em;
}
.tour-image-input input
{
    display: none;
}
.tour-image-upload-btn.loading
{
    opacity: 0.3;
}
.tour-image-preview
{
    width: 4em;
    height: 4em;
    object-fit: cover;
    border-radius: 0.5em;
    cursor: pointer;
}