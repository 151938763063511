.tour-timetable-day
{
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.tour-timetable-day.placeholder
{
    opacity: 0.35;
    padding-bottom: 2em;
}
.tour-timetable-day-entries
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
}
.tour-timetable-day-header
{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    font-family: 'Lexend Giga', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: -0.1em;
}
.tour-timetable-header-line
{
    flex-grow: 1;
    height: 2px;
    background-color: gray;
}