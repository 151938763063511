.spinner {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-inner {
    width: min(3em, 80%);
    height: min(3em, 80%);
    background-image: url("/public/spinner.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}