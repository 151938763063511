.tour-date-entry
{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.5em;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 0.5em;
}
.tour-date-entry-columns
{
    display: flex;
    gap: 0.5em;
    align-items: center;
}
.tour-date-entry-columns > *
{
    flex: 1;
}
.tour-date-entry-row
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5em;
}
.tour-date-entry-row.exclude
{
    flex: 0.5;
}