.user-info
{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
    box-sizing: border-box;
    align-self: flex-start;
    width: 100%;
}
.user-info-title
{
    font-weight: 600;
    font-size: 1.4em;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
    box-sizing: border-box;
    padding: 0.5em 0;
}
.user-info-properties
{
    display: grid;
    grid-template-columns: auto;
}
.user-info-property
{
    box-sizing: border-box;
    padding:  1em 0;
    display: flex;
    gap: 2em;
    border-top: 1px solid #CCC;
    align-items: center;
}
.user-info-label, .ticket-info-value
{
    font-size: 1.2em;
    flex: 1;
}
.user-info-value
{
    font-weight: 600;
}
.user-info-copy
{
    min-width: 8em;
    border: 2px solid transparent;
}
.user-info-copy.copied
{
    background: none;
    color: black;
    font-weight: 400;
    border: 2px solid #f0f0f0;
}