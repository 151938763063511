@keyframes rtl-anim {
    0% {
        opacity: 0;
        transform: translateX(15em);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes ltr-anim {
    0% {
        opacity: 0;
        transform: translateX(-15em);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.panel-container
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.panel-item
{
    width: 100%;
    min-height: 100%;
    max-height: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    box-sizing: border-box;
}
.panel-item:has(.panel-item)
{
    height: 100%;
    max-height: 100%;
}
.panel-item .panel-item
{
    min-height: fit-content;
}
.panel-item.rtl
{
    animation: rtl-anim 0.2s ease-out;
}

.panel-item.ltr
{
    animation: ltr-anim 0.2s ease-out;
}