.ticket-history
{
    display: flex;
    flex-direction: column;
    gap: 2em;
    flex: 1;
    padding: 1em;
    border-radius: 1em;
    background-color: white;
    box-sizing: border-box;
    align-items: stretch;
    height: fit-content;
}
.ticket-history-entries
{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1em;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 0.8em;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.ticket-history-entry-date
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ticket-next-status-container
{
    width: 100%;
}
.ticket-next-status-container button
{
    width: 100%;
    font-size: 1.2em;
}
.ticket-cancel-container
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
    font-size: 1.2em;
}
.status-label
{
    border-radius: 0.5em;
    padding: 1em;
    box-sizing: border-box;
    background-color: var(--status-color);
    color: var(--status-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.status-color-open
{
    --status-color: #FFA500;
    --status-text-color: white;
}
.status-color-booked
{
    --status-color: #008AFF;
    --status-text-color: white;
}
.status-color-reached
{
    --status-color: #FFA500;
    --status-text-color: white;
}
.status-color-confirmed
{
    --status-color: #46e046;
    --status-text-color: white;
}
.status-color-cancelled_user
{
    --status-color: #FF0000;
    --status-text-color: white;
}
.status-color-cancelled_moderator
{
    --status-color: #FF0000;
    --status-text-color: white;
}
.status-color-finished
{
    --status-color: #c300ff;
    --status-text-color: white;
}
.ticket-next-status-button
{
    background-color: var(--status-color);
    color: var(--status-text-color);
}
.ticket-cancel-container textarea
{
    border-radius: 0.5em;
    border: none;
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid black;
    font-family: 'Lexend', sans-serif;
}
.status-apply-color
{
    background-color: var(--status-color);
    color: var(--status-text-color);
}
.ticket-cancel-container
{
    padding: 1.5em;
    box-sizing: border-box;
    border-radius: 1em;
    border: 2px solid red;
}