.transfer-price-applier-entry
{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: minmax(0, 1fr);
    gap: 0.8em;
}
.price-applier-input-group
{
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(3, minmax(0, 1fr));
}
.price-applier-input-group input
{
    min-width: 0;
}
.transfer-price-applier-entry.placeholder
{
    opacity: 0.5;
}
.transfer-price-applier-entry.fixed > .price-applier-input-group:nth-child(3)
{
    grid-column: 3 / span 2;
}
.price-applier-input-group.after-km input
{
    grid-row: 1 / span 2;
    padding: 0;
    margin: 0;
    font-size: 2.5em;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
    font-weight: bold;
    border: none;
    margin-bottom: -0.5em;
}
.price-applier-input-group.after-km input:focus
{
    outline: none;
}
.pricing-rm-btn
{
    display: flex;
    align-items: center;
}