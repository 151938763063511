.sidebar
{
    box-sizing: border-box;
    padding: 1em;
    gap: 0.25em;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: stretch;
}