.input-toggle
{
    --toggle-height: 2.5em;
    --toggle-width: 5em;
    --toggle-padding: 0.25em;
    --inactive-color: #f0f0f0;
    --active-color: black;
    --circle-color: white;
    height: var(--toggle-height);
    width: var(--toggle-width);
    border-radius: var(--toggle-height);
    background-color: var(--inactive-color);
    padding: var(--toggle-padding);
    box-sizing: border-box;
    display: flex;
    transition: 0.2s;
    cursor: pointer;
}
.input-toggle-spacing
{
    flex: 0;
    width: 0%;
    transition: 0.2s;
}
.input-toggle-switch
{
    height: 100%;
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: var(--circle-color);
}
.input-toggle.enabled .input-toggle-spacing
{
    flex: 1;
}
.input-toggle.enabled
{
    background-color: var(--active-color);
}