.bar-pages
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.page-item
{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 0.5em;
    padding: 0.2em 0.3em;
    box-sizing: border-box;
    border-radius: 0.5em;
    transition: 0.1s;
}
.icon-container
{
    width: 1.5em;
    height: 1.5em;
}
.page-item.active
{
    color: white;
    background-color: black;
    transform: scale(1.1);
}
.page-item.active .page-icon
{
    background-color: white;
}
.page-icon {
    display: block;
    background-color: black;
    mask-image: url("/public/icon/icon-ticket.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 100%;
    height: 100%;
}
.page-icon.ticket
{
    mask-image: url("/public/icon/icon-ticket.svg");
}
.page-icon.ticket-cut
{
    mask-image: url("/public/icon/icon-cut-ticket.svg");
}
.page-icon.world
{
    mask-image: url("/public/icon/icon-world.svg");
}
.page-icon.price
{
    mask-image: url("/public/icon/icon-price.svg");
}