.tour-timetable-entry-item {
    display: flex;
    gap: 1em;
    align-items: center;
}
.tour-timetable-entry-item.placeholder {
    opacity: 0.35;
}
.placeholder .tour-timetable-entry-item.placeholder
{
    opacity: 1;
}
.tour-timetable-entry-item input[type="text"] {
    flex: 1;
}
.day-time-selects
{
    display: flex;
    gap: 0.5em;
    align-items: center;
}