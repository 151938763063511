@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Giga:wght@100..900&display=swap');

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotating-icon
{
    animation: spinner 0.75s linear infinite;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lexend', sans-serif;
    user-select: none;
}
button, input, textarea, select {
    font-size: inherit;
    font-family: 'Lexend', sans-serif;
}
input, textarea, select {
    border-radius: 0.5em;
    padding: 0.5em 1em;
    border: 1px solid black;
}
button {
    --bg-color: black;
    --text-color: white;
    --bg-hover-mix-color: white;
    --bg-hover-color: color-mix(in srgb, var(--bg-color), var(--bg-hover-mix-color) 30%);
    border: none;
    background-color: var(--bg-color);
    color: var(--text-color);
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    transition: 0.1s;
}
button:hover
{
    background-color: var(--bg-hover-color);
}
button:disabled
{
    opacity: 0.3;
}
.common-container
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 760px)
{
    body
    {
        max-height: 100vh;
        overflow-y: hidden;
    }
    #root
    {
        height: 100vh;
        max-height: 100vh;
        min-height: 100vh;
        overflow-y: auto;
    }

    .common-container
    {
        display: block;
        position: unset;
    }

    .tour-includes-item
    {
        flex-wrap: wrap;
    }

    .tour-date-entry-columns
    {
        flex-direction: column;
    }

    .tour-date-entry-row
    {
        width: 100%;
    }

    .modal-overlay.enabled
    {
        position: absolute;
    }

    .tours-panel, .edit-tour, .prices-container, .discounts-container
    {
        flex-direction: column;
        gap: 1em;
    }

    .edit-tour > .panel-container
    {
        flex: 1;
    }

    .tour-details-panel
    {
        height:80vh;
    }
    .tour-timetable-day-entries
    {
        display: flex;
        flex-direction: column;
    }

    .tour-timetable-entry-item
    {
        flex-direction: column;

        > input[type="text"]
        {
            width: 100%;
            box-sizing: border-box;
        }

        > .day-time-selects
        {
            width: 100%;

            > select
            {
                flex: 1;
            }
        }
    }

    .dashboard
    {
        min-height: 100vh;
    }

    .login-panel
    {
        width: 100vw;
        height: 100vh;
    }

    .login-container
    {
        min-width: 0;
    }

    .transfer-price-applier-entry
    {
        display: flex;
        flex-direction: column;
    }

    .price-applier-input-group
    {
        display: flex;
        flex-direction: column;
    }

    .price-applier-input-group.after-km input
    {
        margin-bottom: 0;
    }

    .dashboard-edit
    {
        flex: 1;
        justify-content: center;
    }

    .panel-item
    {
        max-height: auto;
        height: auto;
        padding: 0.5em;
        min-height: 100%;
    }

    .tours-grid
    {
        grid-template-columns: 1fr;
    }

    .common-container
    {
        height: auto;
        min-height: 100vh;
    }

    .sidebar
    {
        width: 100%;
    }

    .page-item
    {
        > .page-label
        {
            display: none;
        }

        &.active > .page-label
        {
            display: block;
        }
    }

    .bar-pages
    {
        overflow-x: auto;
        overflow-y: hidden;
        box-sizing: border-box;
        height:fit-content;
    }

    .ticket-container
    {
        flex-direction: column;
        gap: 0.5rem;

        .ticket-buttons
        {
            width: 100%;
            position: unset;

            > *
            {
                width: 100%;
                transform: unset;
                margin: 0;
                text-align: center;
            }
        }
    }

    .edit-ticket-container
    {
        flex-direction: column;
    }
}