.tour-extra
{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .delete
    {
        background-color: rgb(205, 32, 32);

        &:hover
        {
            background-color: rgb(255, 0, 0);
        }
    }
}