.tour-input-group
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5em;
}
.tour-input-group textarea
{
    resize: vertical;
}
.tour-input-group.horizontal
{
    flex-direction: row;
    gap: 1em;
    align-items: center;
}
.tour-input-group.horizontal > *:nth-child(2)
{
    flex: 1;
}