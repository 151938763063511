.ticket-info
{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
    box-sizing: border-box;
    align-self: flex-start;
    width: 100%;
}
.ticket-info-title
{
    font-weight: 600;
    font-size: 1.4em;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
    box-sizing: border-box;
    padding: 0.5em 0;
}
.ticket-info-properties
{
    display: grid;
    grid-template-columns: auto;
}
.ticket-info-property
{
    box-sizing: border-box;
    padding:  1em 0;
    display: flex;
    gap: 2em;
    border-top: 1px solid #CCC;
}
.ticket-info-label, .ticket-info-value
{
    font-size: 1.2em;
    flex: 1;
}
.ticket-info-value
{
    font-weight: 600;
}