.currency-input
{
    display: flex;
    align-items: stretch;
}
.currency-input .currency-symbol
{
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    padding: 0.25em;
    box-sizing: border-box;
}
.currency-input input
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 1;
}