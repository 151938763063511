.tour-dates-panel
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 100%;
}
.tour-date-entries-panel
{
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}