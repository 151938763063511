.error-message
{
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em 1em;
    --error-border: #FF8B8B;
    --error-text: #e55959;
    --error-container: #FFD1D1;
    background-color: var(--error-container);
    border: 2px solid var(--error-border);
    border-radius: 0.5em;
}
.error-message-inner
{
    display: flex;
    flex-direction: column;
    gap: 0.35em;
    color: var(--error-text);
}
.error-title
{
    font-size: 1.2em;
    font-weight: bold;
}
.error-description
{
    font-size: 1em;
    font-weight: 300;
    color: black;
    opacity: 0.8;
}