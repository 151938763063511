.login-panel
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container
{
    gap: 1em;
    padding: 3em;
    border-radius: 1em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-width: max(30%, 30em);
}