.prices-container
{
    width: 100%;
    height: 100%;
    max-height: 100%;
    flex: 1;
    display: flex;
    gap: 2em;
}
.prices-inner-panel
{
    height: 100%;
    flex: 1;
    background-color: white;
    border-radius: 1em;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
}