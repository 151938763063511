.item-selection
{
    display: flex;
    flex-direction: column;

    > .item
    {
        --br: 0.5rem;
        --color: black;
        --bg-col: white;
        --rsize: 1.5rem;
        --irw: 0;
        --rpad: 0.75rem;
        --trans: 0.2s ease-in-out;
        border: 2px solid var(--color);
        border-bottom-width: 1px;
        border-top-width: 1px;
        cursor: pointer;
        padding: 1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background-color: var(--bg-col);

        &:hover
        {
            --bg-col: lightgray;
        }

        &:active > .content, &:active > .title
        {
            transform: scale(0.99);
        }

        &, & > .title, & > .content, & > .content > .description, & > .content > .radio, & > .content > .radio > .inner
        {
            transition: var(--trans);
        }

        &:first-child
        {
            border-top-left-radius: var(--br);
            border-top-right-radius: var(--br);
            border-top-width: 2px;
        }

        &:last-child
        {
            border-bottom-left-radius: var(--br);
            border-bottom-right-radius: var(--br);
            border-bottom-width: 2px;
        }

        > .title
        {
            font-family: 'Lexend Giga', sans-serif;
            letter-spacing: -0.1em;
            font-weight: bold;
            color: var(--color);
        }

        > .content
        {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            > .description
            {
                flex: 1;
                color: var(--color);
            }

            > .radio
            {
                width: var(--rsize);
                height: var(--rsize);
                border-radius: var(--rsize);
                border: 2px solid var(--color);
                display: flex;
                align-items: center;
                justify-content: center;

                > .inner
                {
                    --tsize: calc(var(--rsize) - var(--rpad));
                    width: var(--tsize);
                    height: var(--tsize);
                    border-radius: var(--tsize);
                    background-color: var(--color);
                    transform: scale(var(--irw));
                }
            }
        }

        &.selected
        {
            --color: rgb(18, 143, 201);
            --bg-col: rgb(219, 243, 255);
            --irw: 1;
        }

    }
}