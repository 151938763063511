.transfer-pricing-entries-panel
{
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}
.transfer-pricing-panel
{
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
    width: 100%;
    max-height: 100%;
}