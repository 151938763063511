.ticket-container
{
    display: flex;
    flex-direction: row;
    gap: 2em;
    padding: 1em;
    box-sizing: border-box;
    align-items: center;
    border-radius: 0.5em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    cursor: pointer;
    transition: 0.1s;
    border: 1px solid rgba(0, 0, 0, 0.1);
    user-select: none;
}
.ticket-container.self {
    color: white;
    background-color: black;
}
.ticket-type-name
{

    font-family: 'Lexend Giga', sans-serif;
    font-weight: bold;
    letter-spacing: -0.1em;
}
.ticket-container:hover
{
    transform: translateY(-0.2em);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
.ticket-container.self .ticket-icon {
    background-color: white;
}
.ticket-container .ticket-icon {
    width: 1.5em;
    height: 1.5em;
    background-color: black;
    mask-image: url("/public/icon/icon-ticket.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
}
.ticket-container .ticket-icon .ticket-type-hover
{
    position: absolute;
}
.ticket-container .ticket-icon.transfer 
{
    mask-image: url("/public/icon/icon-right-left.svg");
}
.ticket-container .ticket-icon.tour 
{
    mask-image: url("/public/icon/icon-bus.svg");
}
.ticket-container.assigned > *
{
    opacity: 0.2;
}
.ticket-container .right-align
{
    margin: -0.5em 0;
    margin-left: auto;
    padding: 0.5em 1em;
    box-sizing: border-box;
    opacity: 1;
    border-radius: 3em;
    transform: translateX(2em);
}
.ticket-container .ticket-assignee
{
    background-color: darkgray;
    color: white;
}
.ticket-container .assigned-self
{
    transform: none;
    background-color: white;
    color: black;
    transition: 0.2s;
    width: fit-content;
}
.ticket-container .request-assign
{
    background-color: #008AFF;
    color: white;
    box-shadow: 0px 4px 12px 4px #0088ff00;
    transition: 0.2s;
    width: fit-content;
}
.ticket-container .request-assign:hover
{
    transform: translateX(2em) translateY(-0.1em);
    box-shadow: 0px 4px 16px 2px #0088ffa2;
}
.ticket-container .assigned-self:hover
{
    transform: translateY(-0.1em);
    box-shadow: 0px 4px 16px 2px #0000001a;
    background-color: #FFD1D1;
    color: #e55959;
}
.ticket-container .assigned-self:hover .removeLabel
{
    display: block;
}
.ticket-container .assigned-self:hover .label {
    display: none;
}
.ticket-container .assigned-self .label
{
    display: block;
}
.ticket-container .assigned-self .removeLabel {
    display: none;
}
.ticket-title
{
    flex: 2;
}
.ticket-date
{
    flex: 1;
}
.ticket-buttons
{
    flex: 1;
    position: relative;
    display: flex;
    justify-content: end;
}