.discounts-container {
    display: flex;
    gap: 2em;
    width: 100%;
    height: 100%;

    .column
    {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 2rem;
    }
}