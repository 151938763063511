.tours-panel
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    gap: 2em;
}
.new-tour-btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.tours-grid
{
    background-color: white;
    border-radius: 1em;
    flex: 1;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr 1fr;
    overflow-y: auto;
    padding: 2em;
    box-sizing: border-box;
}