.tour-img-button
{
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.tour-img-button img
{
    min-width: 0;
    min-height: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5em;
    border: 1px solid #9e9e9e;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.tour-img-button button
{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    border-radius: 100%;
    aspect-ratio: 1;
    font-size: 0.5em;
}