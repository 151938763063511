.transfer-pricing-car
{
    width: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    color: black;
    cursor: pointer;

    &.placeholder
    {
        background-color: black;
        color: white;
    }

    > .list-item
    {
        display: flex;
        gap: 1rem;
        align-items: center;

        > .spacing
        {
            flex: 1;
        }
    }

    > .properties
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}