.yes-no-modal
{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: stretch;
    justify-content: stretch;
}
.yes-no-modal .buttons
{
    display: flex;
    gap: 1em;
    justify-content: stretch;
    align-items: stretch;
}
.yes-no-modal .buttons .prompt-button
{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}
.yes-no-modal .buttons .prompt-button.btn-red
{
    background-color: rgb(205, 32, 32);
}