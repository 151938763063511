.tour-general-panel
{
    width: 100%;
    justify-self: start;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.tour-apply-discount
{
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-self: center;
}
.hline
{
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
}
.tour-input-with-unit
{
    display: flex;
}
.tour-input-with-unit input
{
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.tour-input-with-unit .tour-input-unit
{
    border-radius: 0.5em;
    padding: 1em;
    box-sizing: border-box;
    background-color: black;
    color: white;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.tour-price-group
{
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    align-items: center;
    gap: 1em;
}