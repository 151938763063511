.edit-tour
{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2em;
}
.edit-tour .sidebar button
{
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: start;
}
.sidebar-spacing
{
    flex: 1;
}
.delete-tour-btn
{
    background-color: rgb(205, 32, 32);
    transition: 0.1s;
}
.delete-tour-btn:hover
{
    background-color: rgb(255, 32, 32);
}
.apply-tour-changes-btn
{
    transition: 0.1s;
}
.apply-tour-changes-btn:hover
{
    background-color: rgb(95, 95, 95);
}
.edit-tour-panels
{
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    background-color: white;
}