.discount-container
{
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1em;
}
.discount-heading
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.discount-toggle
{
    height: 2.5em;
    width: 5em;
    border-radius: 2.5em;
    background-color: #9c9c9c;
    cursor: pointer;
}
.discount-toggle.enabled
{
    background-color: black;
}
.discount-toggle-circle
{
    height: 2em;
    width: 2em;
    border-radius: 2em;
    background-color: white;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    margin-left: 0.25em;
    transition: 0.2s;
}
.discount-toggle.enabled .discount-toggle-circle
{
    transform: translateX(2.5em);
}
.discount-title
{
    font-size: 1.5em;
    font-weight: 900;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -0.1em;
}
.discount-input-container
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap:1em;
    opacity: 0.1;
    pointer-events: none;
    transition: 0.2s;
}
.discount-input-container.enabled
{
    opacity: 1;
    pointer-events: all;
}
.discount-input-container button {
    opacity: 1;
}
.discount-input-container.enabled button
{
    opacity: 0.1;
    pointer-events: none;
}
.discount-input-container.enabled button.enabled {
    pointer-events: all;
    opacity: 1;
}
.discount-input {
    display: flex;
}
.discount-input input
{
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.discount-percent
{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    padding: 0em 1em;
    box-sizing: border-box;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: black;
    color: white;
}
.discount-title, .discount-heading-content
{
    flex: 1;
}
.discount-description
{
    font-weight: normal;
    opacity: 0.7;
    font-family: 'Lexend', sans-serif;
    font-size: 1rem;
    letter-spacing:normal;
}