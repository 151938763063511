.dashboard
{
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
}
.dashboard-inner
{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}